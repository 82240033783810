.modalBackdrop {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgba(24, 24, 24, 0.42);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalForeground {
  width: 38vw;
  height: 49vh;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 4px;
}

.top {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.exit {
  position: absolute;
  right: 0;
  font-size: 1.3rem;
  font-weight: 500;
}

.middle {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.bottom {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.back,
.confirm {
  outline: transparent;
  border: none;
  background: none;
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  background: #417bff;
}

.back {
  background: #6c757d;
}

.campaignName {
  font-size: 1.4rem;
  font-weight: 500;
  color: #417bff;
}

.arrow {
  height: 3rem;
  width: auto;
}

.priorityWrapper {
  text-align: center;
}

.priorityType {
  font-weight: 400;
  font-size: 0.8rem;
}

.priority {
  font-size: 1.9rem;
}

.exit {
  cursor: pointer;
}
