.table {
  display: flex;
  /* margin: 0 2vw 0 1.3vw; */
}

.column {
  display: flex;
  flex-direction: column;
  /* text-overflow: ellipsis;
  overflow: hidden; */
  max-width: 25%;
  width: 25%;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: relative;
  border: 1px solid #faf0e6;
}

.header {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: white;
  font-weight: 600;
  padding: 0.5rem;
}

.campaignName {
  font-weight: 600;
  font-size: 0.85rem;
  margin: auto;
  text-overflow: ellipsis;
  /* overflow: hidden; */
}

.blue {
  background: #4169e1;
}

.green {
  background: #228b22;
}

.yellow {
  background: #ffd700;
}

.grey {
  background: #708090;
}

.red {
  background: #b22222;
}

.campaignWrapper {
  width: 100%;
}

.statWrapper {
  padding: 0.2rem 0;
  display: flex;
  width: 96%;
  justify-content: space-between;
}

.asf {
  font-size: 0.7rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 0.1rem 0.2rem;
  border-radius: 4px;
}
