.container {
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  outline: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4p;
}

.icon {
  position: absolute;
  right: 0.7vw;
  margin-top: 1vh;
}

.input {
  outline: none;
  border: none;
  width: 20vw;
}
