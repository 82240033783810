.nav {
  display: flex;
  align-items: center;
  height: 3.5rem;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  background: white;
  z-index: 20;
}

.right {
  display: flex;
  align-items: center;
  padding-right: 1.7vw;
}

.right > * {
  position: relative;
  text-decoration: none;
  color: black;
  margin-left: 1.5rem;
  font-weight: 500;
}

.logo {
  transform: scale(0.8);
  cursor: pointer;
}

/* justin arnold */
