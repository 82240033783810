.consoleContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  padding: 1rem 3rem 1rem 1rem;
  min-height: 30vh;
  min-width: 35vw;
  background: white;
  border-radius: 4px;
}

.count {
  font-weight: 800;
  cursor: pointer;
}

.close {
  width: 1.5rem;
  position: absolute;
  right: 3%;
  cursor: pointer;
}

.result {
  margin-bottom: 1rem;
}

.error {
  margin-bottom: 1rem;
  font-weight: 700;
  color: #ef233c;
}
